import { MetaTagEntities } from '../../interfaces/pages';
import { UrlUtils } from '../url/url.utils';

export class PageArticleUtils {
  static shouldDisplayEffyAdsBasedOnPageUrl(pathname: string, metaTags: MetaTagEntities): boolean {
    if (UrlUtils.isPreviewUrl(pathname)) {
      pathname = UrlUtils.getPathnameInPreview(metaTags);
    }

    const keywords = ['pro', 'magazine', 'flash-info', 'communique-presse'];
    for (let i = 0; i < keywords.length; i++) {
      if (pathname.startsWith(`/${keywords[i]}/`) || pathname === `/${keywords[i]}`) return false;
    }

    return true;
  }

  static shouldDisplayCtaWithQuestions(pathname: string, metaTags: MetaTagEntities): boolean {
    if (UrlUtils.isPreviewUrl(pathname)) {
      pathname = UrlUtils.getPathnameInPreview(metaTags);
    }

    const urls = [
      '/travaux-energetique/isolation/isolation-murs/par-l-exterieur',
      '/travaux-energetique/isolation/isolation-murs/par-l-exterieur/1-euro',
      '/travaux-energetique/isolation/isolation-murs/par-l-exterieur/prix',
      '/aide-energetique/renovation-maison-ancienne',
      '/aide-energetique/ma-prime-renov/isolation-murs-exterieur',
      '/aide-energetique/ma-prime-renov/fenetres-parois-vitrees',
      '/aide-energetique/prime-energie',
      '/aide-energetique/simulateur-gratuit-comment-connaitre-montant-aides-travaux',
      '/travaux-energetique/chauffage/poele/poele-a-granule-a-1-euro',
      '/aide-energetique/poele-a-bois',
    ];

    for (let i = 0; i < urls.length; i++) {
      if (pathname === urls[i] || pathname.startsWith(`${urls[i]}?`)) return true;
    }

    return false;
  }
}
