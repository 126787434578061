import { ErrorsData } from '@effy-tech/acquisition-core';

export const errorsList = <ErrorsData>[
  {
    statusCode: '503',
    message: 'erreur interne du serveur',
    isServeurError: true,
    metaData: {
      title: 'Erreur 500 Site indisponible | CALCULEO',
      description: 'Le site est actuellement indisponible. Nous serons de retour très bientôt !',
    },
  },
  {
    statusCode: '404',
    message: 'page non trouvée',
    isServeurError: false,
    links: [
      {
        url: '/subventions',
        label: 'Aides et subventions',
      },
      {
        url: '/eco-travaux',
        label: 'Éco travaux',
      },
      {
        url: '/comment-ca-marche',
        label: 'Comment ça marche ?',
      },
      {
        url: '/parcours/1/aides-subventions',
        label: 'Calculez vos aides',
      },
    ],
    metaData: {
      title: 'Erreur 404 Page non trouvée | CALCULEO',
      description:
        "La page que vous recherchez n'existe pas. Découvrez nos autres offres et le guide de la rénovation énergétique sur https://www.calculeo.fr",
    },
  },
];
