import { Routes } from '@angular/router';
import {
  DynamicFactoryComponent,
  ErrorsCode,
  NotFoundComponent,
  PreviewComponent,
} from '@effy-tech/acquisition-core';

export const routes: Routes = [
  {
    path: 'previewbo',
    component: PreviewComponent,
    pathMatch: 'full',
  },
  {
    path: 'graphql',
    component: NotFoundComponent,
    data: {
      statusCode: ErrorsCode.NOT_FOUND_CODE,
    },
  },
  {
    path: 'erreur',
    component: NotFoundComponent,
    pathMatch: 'full',
    data: {
      statusCode: ErrorsCode.SERVER_ERROR_CODE,
    },
  },
  {
    path: '**',
    component: DynamicFactoryComponent,
  },
];
