import { Injectable } from '@angular/core';
import {
  ConfigService,
  FooterService,
  ICguLink,
  INewFooter,
  Site,
  WebsiteThemes,
  ctaSubject,
  getSiteUrl,
  getSocialLinks,
  socialsLinkText,
} from '@effy-tech/acquisition-core';
import { EffyIcons } from '@effy-tech/angular-common';

@Injectable({
  providedIn: 'root',
})
export class CalculeoFooterService extends FooterService {
  constructor(private readonly configService: ConfigService) {
    super();
  }

  getContent(): INewFooter {
    const env = this.configService.getEnv();

    return {
      logo: {
        url: 'assets/new-footer/logo_calculeo_white.svg',
        alt: 'logo site Calculeo',
      },
      title:
        'Une marque du groupe Effy,<span class="d-block">n°1 de la rénovation énergétique en ligne<span>',
      ctaSubject: ctaSubject,
      cta: {
        url: '/parcours/1/travaux-aides',
        title: 'Demander un devis',
      },
      siteLogo: {
        title: 'Les sites du groupe Effy',
        logos: [
          {
            url: 'https://www.effy.fr/common-assets/images/logo-effy-blanc.svg',
            alt: 'Logo Effy',
            link: getSiteUrl(Site.EFFY, env),
            site: WebsiteThemes.effy,
            icon: EffyIcons.EFFY_WHITE_LOGO
          },
          {
            url: 'assets/new-footer/logo_quelleenergie_white.svg',
            alt: 'Logo Quelle Énergie',
            link: getSiteUrl(Site.QE, env),
            site: WebsiteThemes.qe,
            icon: EffyIcons.QUELLEENERGIE_WHITE_LOGO
          },
          {
            url: 'assets/new-footer/logo_lpe_white.svg',
            alt: 'Logo La Prime Énergie',
            link: getSiteUrl(Site.LPE, env),
            site: WebsiteThemes.lpe,
            icon: EffyIcons.LAPRIMEENERGIE_WHITE_LOGO
          },
        ],
      },
      columns: [
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Guides de travaux',
            },
            {
              mainLink: false,
              link: 'Pompe à chaleur',
              url: '/guide/pompe-a-chaleur',
            },
            {
              mainLink: false,
              link: 'Poêle à bois',
              url: '/guide/poele-a-bois',
            },
            {
              mainLink: false,
              link: 'Isolation des murs',
              url: '/guide/isolation-des-murs-exterieur',
            },
            {
              mainLink: false,
              link: 'Aides financières',
              url: '/guide/aides-travaux-renovation-energetique',
            },
            {
              mainLink: false,
              link: 'Financement fenêtres',
              url: '/guide/financer-vos-fenetres',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Conseils & Offres',
            },
            {
              mainLink: false,
              link: 'Isolation',
              url: '/conseils-travaux/isolation',
            },
            {
              mainLink: false,
              link: 'Chauffage',
              url: '/conseils-travaux/chauffage',
            },
            {
              mainLink: false,
              link: 'Energie solaire',
              url: '/conseils-travaux/solaire',
            },
            {
              mainLink: false,
              link: 'Ventilation',
              url: '/conseils-travaux/ventilation',
            },
            {
              mainLink: false,
              link: 'FAQ',
              url: '/faq',
            },
          ],
        },
      ],
      socialsLink: {
        text: socialsLinkText,
        links: getSocialLinks(),
      },
      cguLinks: [
        {
          text: 'Mentions légales',
          url: '/mentions-legales',
        },
        {
          text: 'CGV',
          url: '/conditions-generales-vente',
        },
        {
          text: `CGU`,
          url: '/cgu',
        },
        {
          text: 'Vie Privée',
          url: '/vie-privee',
        },
        {
          text: 'Politique des cookies',
          url: '/politique-des-cookies',
        },
      ],
    };
  }

  private getCguLinks(): ICguLink[] {
    return [
      {
        text: 'Mentions légales',
        url: '/mentions-legales',
      },
      {
        text: 'CG Partenaires',
        url: '/conditions-generales-acces-reseau-partenaires-effy',
      },
      {
        text: 'CGV',
        url: '/conditions-generales-vente',
      },
      {
        text: `CGU`,
        url: '/cgu',
      },
      {
        text: 'Vie Privée',
        url: '/vie-privee',
      },
      {
        text: 'Politique des cookies',
        url: '/politique-des-cookies',
      },
    ];
  }
}
