import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '@effy-tech/acquisition-header';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { LoggerService } from '@effy-tech/common/loggers';
import { filter as lodashFilter } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AnalyticsComponent } from '../../components/analytics/analytics.component';
import { IBreadCrumbName } from '../../components/breadcrumb/breadcrumb';
import { FooterComponent } from '../../components/footer/footer.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { typenamePreHeaderBloc } from '../../components/pre-header/pre-header';
import { PreHeaderComponent } from '../../components/pre-header/pre-header.component';
import { ErrorValue } from '../../error/error.model';
import { ResponseError } from '../../error/error.service';
import { GetUrlService } from '../../graphql/getUrl.service';
import { RequestName } from '../../graphql/graphql';
import { GetDataService } from '../../graphql/graphql.service';
import { DynamicComponent, DynamicComponentData, IDynamicComponentOptions } from '../../interfaces/dynamic-types';
import { IPage, IPageArticle } from '../../interfaces/pages';
import { ConfigService } from '../../modules/config/config.service';
import { BreadcrumbPresenter } from '../../presenters/commons/breadcrumb.presenter';
import { NewHeaderPresenter } from '../../presenters/commons/header';
import { MainPagesPresenter } from '../../presenters/main-pages.presenter';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { MetaTagsService } from '../../services/meta-tags';
import { PageScrollingService } from '../../services/page-scrolling/page-scrolling.service';
import { isEmpty } from '../../utils/object/object.utils';
import { DefaultPageComponent } from '../default/default.component';
import { pageMapping, shouldDisplayPhoneInsert } from './dynamic';

@Component({
  selector: 'nscf-dynamic',
  templateUrl: './dynamic.component.html',
  standalone: true,
  imports: [AnalyticsComponent],
})
export class DynamicFactoryComponent extends BasicThemedComponentDirective implements OnInit, AfterViewInit {
  page: IPage;
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  dynamicComponentsData: DynamicComponentData[] = [];
  @Input() options = <IDynamicComponentOptions>{
    hasFooter: true,
    hasHeader: true,
    hasBreadCrumb: true,
    hasScrollTo: true,
    hasMeta: true,
    activeClassTemplate: true,
  };
  private readonly UrlSegments: string;
  private readonly UrlAnchor: string;

  constructor(
    public readonly getDataService: GetDataService,
    public readonly metaTagsService: MetaTagsService,
    private readonly pageScrollingService: PageScrollingService,
    private readonly router: Router,
    private readonly location: Location,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly renderer: Renderer2,
    private readonly getUrlService: GetUrlService,
    private readonly logger: LoggerService,
    private readonly configService: ConfigService,
    private readonly deviceManagerService: DeviceManagerService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.UrlSegments = this.getUrlService.getUrlSegment();
    this.UrlAnchor = this.getUrlService.getUrlAnchor();
  }

  ngOnInit(): void {
    this.theme = this.configService.getTheme();
  }

  ngAfterViewInit() {
    this.getAllData()
      .pipe(map(this.mapData.bind(this)), take(1))
      .subscribe((page: IPage) => {
        if (!page) {
          return;
        }
        this.page = page;

        // start building the page
        this.getDynamicContentPage(this.options.hasBreadCrumb);
        // set optional parts
        this.setOptionalParts();
        // finish building the page
        this.setDynamicComponents();

        //this.cdr.detectChanges();
      });
    this.cdr.detectChanges();
  }

  createComponent(type, content: any): void {
    const component = this.container.createComponent<DynamicComponent>(type).instance;
    component.data = content;
    component.theme = this.theme;
  }

  computeBodyClass(typename: string): string {
    // remove 'Node' text from Drupal typename and lower it
    return `pagetype-${typename.substring(4).toLowerCase()}`;
  }

  public getAllData(): Observable<any> {
    return this.getDataService.getData(this.UrlSegments).pipe(
      catchError((error: ResponseError) => {
        const statusCode = error.status ?? ErrorValue.NOT_FOUND_CODE.statusCode;
        this.setDynamicComponentError(statusCode, this.getUrlService.getUrlSegment(), error.message);
        return of(null);
      }),
      switchMap(dataRowPage => {
        if (!dataRowPage) {
          return of(null);
        }
        return this.getDataService
          .getRequestData(RequestName.MENU_REQ, { name: dataRowPage?.data?.route?.entity?.fieldHeader?.entity?.fieldMenu?.targetId })
          .pipe(
            map(headerMenuData => [
              this.parsePage(dataRowPage),
              NewHeaderPresenter.getHeader(
                dataRowPage?.data?.route?.entity?.fieldHeader,
                headerMenuData,
                this.configService.getEnvironment()
              ),
            ])
          );
      }),
      catchError((error: ResponseError) => {
        const statusCode = error.status ?? ErrorValue.NOT_FOUND_CODE.statusCode;
        this.setDynamicComponentError(statusCode, this.getUrlService.getUrlSegment(), ErrorValue.NOT_FOUND_CODE.message);
        return of(null);
      })
    );
  }

  mapData(pageData) {
    if (!pageData) {
      return;
    }
    const [data, headerData] = pageData;
    if (data && data.redirectTo) {
      this.getUrlService.redirectWithHttpCode(data.redirectTo.code, data.redirectTo.target.path);
      return;
    }
    if (!data.page) {
      return;
    }

    return <IPage>{
      ...data.page,
      urlAnchor: data.urlAnchor,
      urlSegments: data.urlSegments,
      header: { ...data.page.header, ...headerData },
    };
  }

  setDynamicComponentError(statusCode: number, from, messageError: string): void {
    this.dynamicComponentsData = [
      {
        type: NotFoundComponent,
        content: {
          statusCode,
          from,
          messageError,
        },
        typename: null,
      },
    ];
    for (const data of this.dynamicComponentsData) {
      try {
        this.createComponent(data.type, data.content);
      } catch (e) {
        this.logger.error('DynamicFactoryComponent: Erreur à la création du component lié à ' + data.typename, e);
      }
    }
  }

  private parsePage = (result: any) => {
    if (!result.data?.route) {
      throw new Error(ErrorValue.NOT_FOUND_CODE.message);
    } else if (result.data.route && result.data.route['code'] && result.data.route['target']) {
      // Cas d'une page dont l'url a été changée. Redirection 301
      return { redirectTo: result.data.route };
    } else {
      const mainPagesPresenter = new MainPagesPresenter(this.logger, this.location);
      const breadPresenter = new BreadcrumbPresenter().parse(result.data.route['breadcrumb']);
      // Parse the result to map data according to our interfaces
      const parsedPage = mainPagesPresenter.parse({
        ...result.data.route['entity'],
        actualiteThemeId: result.data.actualiteThemeId,
        breadcrumb: breadPresenter,
      });
      return {
        page: parsedPage,
        urlAnchor: this.UrlAnchor,
        urlSegments: this.UrlSegments,
      };
    }
  };

  private setOptionalParts() {
    if (this.options.hasHeader && !isEmpty(this.page.header)) {
      this.getDynamicHeader();
    }
    if (this.options.hasFooter) {
      this.getDynamicFooter();
    }
    if (this.page.preHeader) {
      this.setPreHeader();
    }
    if (this.options.hasScrollTo) {
      this.setScroll();
    }
    if (this.options.hasMeta) {
      this.setMetaTags();
    }
    if (this.options.activeClassTemplate) {
      this.loadClassTemplate();
    }
  }

  private getDynamicHeader() {
    this.dynamicComponentsData = [
      {
        type: HeaderComponent,
        content: {
          ...this.page.header,
          shouldDisplayPhoneInsert: shouldDisplayPhoneInsert(this.page?.__typename),
          isDesktop$: this.deviceManagerService.deviceType$().pipe(map(deviceType => deviceType.bigViewPort)),
        },
        typename: this.page.__typename,
      },
      ...this.dynamicComponentsData,
    ];
  }

  private getDynamicFooter() {
    let contentFooter = { isPro: this.page?.header?.isPro, showPreFooter: false };
    if (this.page.__typename === 'NodePageArticle') {
      const pageContent = this.page as IPageArticle;
      contentFooter = {
        ...contentFooter,
        showPreFooter: pageContent.showPostHeader,
      };
    }
    this.dynamicComponentsData = [
      ...this.dynamicComponentsData,
      {
        type: FooterComponent,
        content: contentFooter,
        typename: this.page.__typename,
      },
    ];
  }

  private setPreHeader() {
    this.dynamicComponentsData = [
      ...this.dynamicComponentsData,
      {
        type: PreHeaderComponent,
        content: this.page.preHeader,
        typename: typenamePreHeaderBloc,
      },
    ];
  }

  private setDynamicComponents(): void {
    for (const data of this.dynamicComponentsData) {
      try {
        this.createComponent(data.type, data.content);
      } catch (e) {
        this.logger.error('DynamicFactoryComponent: Erreur à la création du component lié à ' + data.typename, e);
      }
    }
  }

  private getDynamicContentPage(hasBreadCrumb): void {
    this.dynamicComponentsData = [];
    // declare specific pages if needed

    const dynamicComponent = {
      type: pageMapping.hasOwnProperty(this.page.__typename) ? pageMapping[this.page.__typename] : DefaultPageComponent,
      content: this.page,
      typename: this.page.__typename,
    };

    if (!hasBreadCrumb) {
      dynamicComponent.content.breadcrumb = null;
      dynamicComponent.content['blocks'] = lodashFilter(dynamicComponent.content['blocks'], component => {
        return component.__typename !== IBreadCrumbName;
      });
    }

    this.dynamicComponentsData.push(dynamicComponent);
  }

  @HostListener('window:load')
  private setScroll() {
    const that = this;

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // if there is an anchor in the URL, scroll to the element
    if (this.page && this.page.urlAnchor) {
      that.pageScrollingService.scrollTo(`#${this.page.urlAnchor}`);
    }

    // bind all anchors behaviors
    const links = document.querySelectorAll('a');
    links.forEach(link => {
      // handle only anchor links (with hash)
      if (link.hash) {
        link.addEventListener('click', function (e) {
          e.preventDefault();
          e.stopPropagation();

          // scroll to the element
          that.pageScrollingService.scrollTo(this.hash, 80);
          // then push the hash in the current url
          that.location.go(that.router.url.split('#')[0] + this.hash);
          return;
        });
      }
    });
  }

  private setMetaTags() {
    if (this.page.metatags && this.page.metatags.length) {
      this.metaTagsService.setMetatags(this.page.metatags);
    }
  }

  private loadClassTemplate() {
    if (this.page.__typename) {
      this.renderer.addClass(this.document.body, this.computeBodyClass(this.page.__typename));
    }
    this.renderer.addClass(this.document.body, this.page.template);
  }
}
