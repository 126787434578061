<div *ngIf="showConnection" class="connection">
  <nscf-header-cta-button-connected
    *ngIf="labelIdentity"
    [labelUser]="labelIdentity"
    [btnClass]="'big-btn espace-client'"
    [btnIcon]="connection?.link?.icon?.url"
    [buttonStyle]="buttonStyle()"
  >
  </nscf-header-cta-button-connected>
  <nscf-header-cta-button
    *ngIf="!labelIdentity"
    [btnClass]="'big-btn espace-pro'"
    [ctaLink]="connection?.link"
    [theme]="theme"
    [btnIcon]="connection?.link?.icon?.url"
    [buttonStyle]="buttonStyle()"
  >
  </nscf-header-cta-button>
</div>
