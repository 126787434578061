import { IQueriesId } from '@effy-tech/acquisition-core';

export const QUERIES_ID: IQueriesId = {
  HEADING_ARTICLE: 1, // heading article summary "block heading article"
  HEADING_QUESTIONS: 2, // Question articles "block questions article"
  CARDIFICATION_NHP: 3, // cardification "block actu nhp"
  CARDIFICATION_MERGE: 4, // cardification "multi"
  CARDIFICATION_THEME: 5, // cardification "block theme"
  CARDIFICATION_MONO: 6, // cardification "mono"
  CARDIFICATION_MONO_PROMO: 7, // cardification "mono" avec l'ajout de la promotion en front page
  CARDIFICATION_MULTI_PROMO: 8, // cardification "multi" avec l'ajout de la promotion en front page
  CARDIFICATION: 9, // cardification avec les pages offres et articles séparées
  GETPAGE: 10, // query générale pour les pages
  GETMENU: 14, // query des menus
  PREVIEW: 15, // query de la preview
  VIDEO: 16, // query pour les videos
  THEME: 17, // query pour les thèmes
  CARDS_MEDIA: 18, // query pour les card article et video du bloc media,
  ARTICLE_ACTU: 19,
  HEADER: 20,
};
