import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../../modules/config/config.service';

@Component({
  selector: 'nscf-analytics',
  templateUrl: 'analytics.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsComponent {
  readonly url = this.sanitizer.bypassSecurityTrustResourceUrl(
    `https://www.googletagmanager.com/ns.html?id=` + this.configService.getEnvironment()?.gtmTag
  );

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly configService: ConfigService
  ) {}
}
