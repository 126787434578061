import { IHeader } from '@effy-tech/acquisition-header';

export const HEADER_DATA = {
  navbar: {
    links: [
      {
        url: '',
        label: 'Les aides financières',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: 'Les aides nationales',
                    url: '',
                    links: [
                      {
                        url: '/subventions/prime-energie',
                        label: 'La prime énergie',
                      },
                      {
                        url: '/subventions/ma-prime-renov',
                        label: "Ma Prime Rénov'",
                      },
                      {
                        url: '/subventions/cheque-energie',
                        label: 'Le chèque énergie',
                      },
                      {
                        url: '/subventions/la-tva-reduite',
                        label: 'La TVA réduite',
                      },
                      {
                        url: '/subventions/eco-pret-a-taux-zero/l-eco-ptz-en-bref',
                        label: "L'éco-prêt à taux zéro",
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: 'https://calculeo.fr/parcours/1/travaux-aides',
                  label: 'Trouver mes aides',
                },
              },
              {
                subItems: [
                  {
                    title: 'Les aides régionales',
                    url: '',
                    links: [
                      {
                        url: '/subventions/rhone-alpes',
                        label: 'Rhônes-Alpes',
                      },
                      {
                        url: '/subventions/pays-de-la-loire',
                        label: 'Pays de la Loire',
                      },
                      {
                        url: '/subventions/aquitaine',
                        label: 'Aquitaine',
                      },
                      {
                        url: '/subventions/bretagne',
                        label: 'Bretagne',
                      },
                      {
                        url: '/subventions/ile-de-france',
                        label: 'Ile de France',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                type: 'image-wave',
                link: {
                  url: '/subventions/ma-prime-renov/demarches',
                  label: "Tuto MaPrimeRenov'",
                },
                url: 'https://bo.calculeo.fr/sites/default/files/2021-12/EFFY_HEARDER_264x254_Tuto_MaPrimeRenov%402x.jpg',
                width: 528,
                height: 508,
                alt: 'tuto-maprimerenov',
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Nos conseils travaux',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: 'Isolation',
                    url: '/conseils-travaux/isolation',
                    links: [
                      {
                        url: '/conseils-travaux/isolation/combles',
                        alt: 'isolation-combles',
                        label: 'Combles',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/isolation-combles.svg',
                      },
                      {
                        url: '/conseils-travaux/isolation/murs',
                        alt: 'isolation-murs',
                        label: 'Murs',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/isolation-murs.svg',
                      },
                      {
                        url: '/conseils-travaux/isolation/sols',
                        alt: 'isolation-sol',
                        label: 'Sol',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/isolation-sol.svg',
                      },
                      {
                        url: '/conseils-travaux/isolation/fenetres',
                        alt: 'fenetres',
                        label: 'Fenêtres',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/fenetres.svg',
                      },
                      {
                        url: '/conseils-travaux/isolation/toiture',
                        alt: 'isolation-toiture',
                        label: 'Toiture',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/isolation-toiture.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: 'https://calculeo.fr/parcours/1/isolation',
                  label: 'Isoler ma maison',
                },
              },
              {
                subItems: [
                  {
                    title: 'Chauffage',
                    url: '/conseils-travaux/chauffage',
                    links: [
                      {
                        url: '/conseils-travaux/chauffage/pompe-a-chaleur',
                        alt: 'pac',
                        label: 'Pompe à chaleur',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/pac.svg',
                      },
                      {
                        url: '/conseils-travaux/chauffage/gaz',
                        alt: 'chauffage-gaz',
                        label: 'Chauffage au gaz',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/gaz.svg',
                      },
                      {
                        url: '/conseils-travaux/chauffage/bois',
                        alt: 'chauffage-bois',
                        label: 'Chauffage au bois',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/bois.svg',
                      },
                      {
                        url: '/conseils-travaux/chauffage/electrique',
                        alt: 'chauffage-electrique',
                        label: 'Chauffage électrique',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/electricite.svg',
                      },
                      {
                        url: '/conseils-travaux/chauffage/solaire',
                        alt: 'chauffage-solaire',
                        label: 'Chauffage solaire',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/solaire.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: 'https://calculeo.fr/parcours/1/pompe-a-chaleur/',
                  label: 'Changer mon chauffage',
                },
              },
              {
                subItems: [
                  {
                    title: 'Solaire',
                    url: '/conseils-travaux/solaire',
                    links: [
                      {
                        url: '/eco-travaux/panneaux-solaires-photovoltaiques',
                        alt: 'panneaux-photovoltaiques',
                        label: 'Panneaux photovoltaïques',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/panneaux-photovoltaiques.svg',
                      },
                      {
                        url: '/eco-travaux/les-travaux-d-economies-d-energie/le-panneau-solaire-thermique',
                        alt: 'panneaux-thermiques',
                        label: 'Panneaux thermiques',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/panneaux-thermiques.svg',
                      },
                      {
                        url: '/eco-travaux/chauffe-eau-solaire-individuel-cesi',
                        alt: 'chauffe-eau-solaire',
                        label: 'Chauffe-eau solaire',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/systeme-solaire-combine.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                subItems: [
                  {
                    title: 'Ventilation',
                    url: '/conseils-travaux/ventilation',
                    links: [
                      {
                        url: '/eco-travaux/ventilation-mecanique-controlee/la-vmc-a-double-flux',
                        alt: 'vmc-double-flux',
                        label: 'VMC double flux',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/vmc-double-flux.svg',
                      },
                      {
                        url: '/eco-travaux/ventilation-mecanique-controlee/la-vmc-simple-flux',
                        alt: 'vmc-simple-flux',
                        label: 'VMC simple flux',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/vmc-simple-flux.svg',
                      },
                      {
                        url: '/eco-travaux/la-ventilation/les-systemes-naturels',
                        alt: 'ventilation-naturelle',
                        label: 'Ventilation naturelle',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/ventilation-naturelle-3.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: 'https://calculeo.fr/parcours/1/travaux-aides',
                  label: 'Lancer mes travaux',
                },
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Notre accompagnement',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: '',
                    url: '',
                    links: [
                      {
                        url: '/eco-travaux/les-travaux-d-economies-d-energie/l-audit-energetique',
                        alt: 'audit-energetique',
                        label: "L'audit énergétique",
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/audit-2.svg',
                      },
                      {
                        url: '/eco-travaux',
                        alt: 'travaux',
                        label: 'Comprendre les travaux',
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/chantier-1.svg',
                      },
                      {
                        url: '/eco-travaux/les-travaux-d-economies-d-energie/trouver-un-artisan-rge',
                        alt: 'artisan',
                        label: "L'artisan RGE",
                        icon: 'https://bo.calculeo.fr/sites/default/files/2021-12/artisan-partenaire.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                type: 'image-wave',
                link: {
                  url: '/subventions',
                  label: 'Obtenir mes aides',
                },
                url: 'https://bo.calculeo.fr/sites/default/files/2021-12/EFFY_HEARDER_264x254_Simuler_mes_aides%402x.jpg',
                width: 528,
                height: 508,
                alt: 'obtenir-aides',
              },
            ],
          },
        },
      },
    ],
  },
  logo: {
    url: 'https://bo.calculeo.fr/sites/default/files/2020-10/logo_calculeo.svg',
    title: '',
    alt: 'Logo Calculeo',
    link: '/',
  },
  connection: {
    link: {
      url: 'https://www.effy.fr/espace-client/calculeo/se-connecter',
      title: 'Espace client',
      options: {
        attributes: {
          target: '_self',
          rel: '',
        },
      },
      icon: {
        url: 'https://bo.calculeo.fr/sites/default/files/2021-12/connexion-particulier-calculeo.svg',
        width: 24,
        height: 24,
        alt: 'connexion-particulier',
      },
    },
    isConnected: false,
  },
  phoneInsert: {
    phoneHook: 'Appelez-nous !',
    phoneType: 'reglementaire',
    phoneNumber: '01 73 00 67 80',
    accompanimentPhone: {
      format: 'full_html',
      processed: '<p>du lundi au vendredi - 8h à 19h</p>\n',
      value: '<p>du lundi au vendredi - 8h à 19h</p>\r\n',
    },
  },
  linkPart: {
    url: 'https://calculeo.fr/parcours/1/travaux-aides',
    title: 'JE CALCULE MES AIDES',
    options: {
      attributes: {
        target: '_self',
        rel: '',
      },
    },
  },
  env: null,
  isPro: false,
} as unknown as IHeader;
