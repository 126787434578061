
<div *ngIf="data.isDesktop$ | async; then desktopBlock; else mobileBlock"></div>
<ng-template #desktopBlock>
  <header class="header-v3" [ngClass]="{'scroll-over-height': scrollOverHeightHeader}">
    <div class="header-v3__wrapper">
      <div class="header-v3__wrapper-logo-link">
        <nscf-logo [logo]="data.logo"></nscf-logo>
        <nscf-new-phone-block *ngIf="data?.phoneInsert?.phoneNumber || isRegulatory"
          [hidden]="scrollBack || !scrollOverHeightHeader || !data.phoneInsert" [phoneInsert]="data.phoneInsert"
          [theme]="theme" [isDesktop]="true" class="phone-bloc phone-block--scroll">
        </nscf-new-phone-block>
        <div [hidden]="scrollOverHeightHeader && !scrollBack && data.phoneInsert"
          class="effy-container header-v3-connected__container header-v3__main-links">
          <nscf-navbar [connection]="data.connection" [isDesktop]="true" [linkPart]="data.linkPart"
            [navbar]="data.navbar" [phoneInsert]="data.phoneInsert" [theme]="theme" [scrollOverHeight]="scrollOverHeightHeader">
          </nscf-navbar>
        </div>
      </div>
      <div class="header-v3__right-buttons">
        <nscf-connection (connected)="onConnected($event)" [connection]="data.connection" [env]="data.env"
          [isPro]="data.isPro" [theme]="theme" *ngIf="isBrowser" [scrollOverHeight]="scrollOverHeightHeader"></nscf-connection>
        <nscf-header-cta-button *ngIf="data.linkPart && !connected()" [btnClass]="'cta-primary big-btn'"
          [ctaLink]="data.linkPart" [theme]="theme" [buttonStyle]="secondButtonStyle()" [showChevronIcon]="true"></nscf-header-cta-button>
      </div>
    </div>
  </header>
  <nscf-new-phone-block *ngIf="(data?.phoneInsert?.phoneNumber || isRegulatory) && !scrollOverHeightHeader"
    [phoneInsert]="data.phoneInsert" [theme]="theme" class="phone-bloc" [isDesktop]="true">
  </nscf-new-phone-block>
</ng-template>

<ng-template #mobileBlock>
  <header class="header-v3 mobile" [ngClass]="{'scroll-over-height': scrollOverHeightHeader}">
    <div class="header-v3__wrapper">
      <div class="mobile-menu-burger">
        <input class="menu-checkbox" id="menu-mobile" name="menu-mobile" type="checkbox" />
        <nscf-logo [logo]="data.logo" [ngClass]="{'new-da': isNewArtisticDirection()}"></nscf-logo>
        <label class="menu-toggle" for="menu-mobile" title="Menu">
          <span class="line line-1"></span>
          <span class="line line-2"></span>
          <span class="line line-3"></span>
        </label>
        <nscf-navbar [connection]="data.connection" [isDesktop]="false" [linkPart]="data.linkPart"
          [navbar]="data.navbar" [phoneInsert]="data.phoneInsert" [scrollOverHeight]="scrollOverHeightHeader" [theme]="theme"></nscf-navbar>
      </div>
    </div>
  </header>

  <div #mobileRightPanel class="mobile-right-panel">
    <div class="scrollable-panel"></div>
  </div>
</ng-template>