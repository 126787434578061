<ng-container *ngIf="{ isMobileOrTablet: isMobileOrTablet$ | async } as vm">
  <section class="top-page container-fluid" *ngIf="!vm.isMobileOrTablet">
    <h1 class="top-page-title">{{ data.title }}</h1>
    <div class="top-page-photo-container">
      <ng-container *ngTemplateOutlet="scoring"></ng-container>
      <button *ngIf="data?.carousel" (click)="openCarouselOnImageIndex(0)" class="top-page-photo top-page-photo-1">
        <img [src]="data?.carousel[0]?.url" [attr.alt]="data?.carousel[0]?.alt" fetchPriority="high" />
      </button>
      <ng-container *ngFor="let image of data?.carousel | slice: 1; let i = index">
        <button (click)="openCarouselOnImageIndex(i + 1)" class="top-page-photo">
          <img class="top-page-photo-{{ i + 2 }}" [src]="image.url" [attr.alt]="image?.alt" />
        </button>
      </ng-container>
    </div>
  </section>
  <section class="top-page__mobile" *ngIf="vm.isMobileOrTablet">
    <nscf-top-page-mosaic-carousel
      [isScrollable]="isCarouselLengthFive"
      [imagesSrc]="photosSrc"
      (openCarouselOnImageIndex)="openCarouselOnImageIndex($event)"
    ></nscf-top-page-mosaic-carousel>
    <h1 class="top-page-title">{{ data.title }}</h1>
    <ng-container *ngTemplateOutlet="scoring"></ng-container>
  </section>
</ng-container>

<ng-template #scoring>
  <nscf-trustpilot-micro-star *ngIf="data.enableTrustPilot"></nscf-trustpilot-micro-star>
</ng-template>

<div *ngIf="openedCarousel()" (click)="openedCarousel.set(false)" class="overlay">
  <button class="overlay-button close-button" alt="fermer carousel">
    <img src="assets/icons/cross.svg" alt="croix" width="40" height="40" />
  </button>
  <div class="carousel">
    <button
      *ngFor="let image of data?.carousel; let i = index"
      class="carousel-button"
      [ngClass]="{ 'carousel-button-selected': i === selectedImageIndex }"
      (click)="selectImageIndex($event, i)"
    >
      <img
        #carouselImages
        class="carousel-image"
        [src]="image.url"
        [attr.alt]="image?.alt"
        [width]="image.width"
        [height]="image.height"
        loading="lazy"
      />
    </button>
  </div>
  <div *ngIf="data?.carousel?.length > 1" class="overlay-button-container">
    <button (click)="selectImageIndex($event, selectedImageIndex - 1)" class="overlay-button" alt="image précedente">
      <img src="assets/arrow/arrow-right-white.svg" alt="flêche précédente" class="previous-arrow" width="22" height="22" />
    </button>
    <button (click)="selectImageIndex($event, selectedImageIndex + 1)" class="overlay-button" alt="image suivante">
      <img src="assets/arrow/arrow-right-white.svg" alt="flêche suivante" width="22" height="22" />
    </button>
  </div>
</div>
