// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.

import { EffyEnvironment } from "@effy-tech/angular-common";

// The list of which env maps to which file can be found in `.angular.json`.
const URL_BASE_BO =
  process.env.URL_BASE_BO || 'https://bo.dev.calculeo.quelleenergie.net';
const URL_BASE_BO_LOCAL =
  process.env.URL_BASE_BO_LOCAL || 'http://calculeo-back-api-service:800';

export const environment = {
  production: false,
  env: EffyEnvironment.Dev,
  graphqlApiEndpoint: URL_BASE_BO_LOCAL + '/graphql',
  url_base_bo: URL_BASE_BO + '/',
  url_base_bo_local: URL_BASE_BO_LOCAL + '/',
  gtmTag: 'GTM-WX9K7DK',
  logo: {
    url: 'https://dev.effy.quelleenergie.net/common-assets/images/logo_calculeo.svg',
  },
  ecGatewayNginx: 'https://ec.gateway.dev.effyconnect.net/',
  apiKey: 'AIzaSyB5PhG4Q0W5elQFbiZ4Aps5mL5UaQYE1LQ',
};
