<div *ngIf="isOverMobile">
  <div
    *ngIf="element.video?.video.length || element.image || element.map?.isActive; then mediaContentDesktop; else withoutMediaDesktop"
  ></div>
  <ng-template #mediaContentDesktop>
    <div class="container">
      <div [ngClass]="[mediaLocation ? 'img--' + mediaLocation : '']" class="spacing-block-text">
        <div class="row">
          <div class="img-container col-6 col-md-12 col-lg-6">
            <nscf-map *ngIf="element.map?.isActive" [map]="element.map" [theme]="theme" class="text__img d-md-none d-lg-block"></nscf-map>
            <img
              *ngIf="element.image?.url && !element.map?.isActive && !element.video?.video.length"
              [alt]="element.image?.alt"
              [src]="element.image?.url"
              loading="lazy"
              class="text__img d-md-none d-lg-block"
            />
            <nscf-video-text
              *ngIf="element.video?.video.length"
              [data]="element.video"
              [isDesktop]="isOverMobile"
              class="text__img d-md-none d-lg-block"
            ></nscf-video-text>
          </div>
          <div class="col-6 col-md-10 col-lg-6 mx-auto text text-container">
            <div class="text__wrapper-content">
              <nscf-title
                *ngIf="element.title"
                [id]="'anchor_' + element.id"
                [selector]="element.isFirstTitle ? 'h1' : 'h2'"
                [title]="element.title"
                [shouldDisplaySeparator]="true"
              >
              </nscf-title>
              <img
                *ngIf="element.image?.url && !element.map?.isActive && !element.video?.video.length"
                [alt]="element.image?.alt"
                [src]="element.image?.url"
                loading="lazy"
                class="text__img d-none d-md-block d-lg-none"
              />
              <nscf-map
                *ngIf="element.map.isActive"
                [map]="element.map"
                [theme]="theme"
                class="text__img d-none d-md-block d-lg-none"
              ></nscf-map>
              <nscf-video-text
                *ngIf="element.video?.video.length"
                [data]="element.video"
                [isDesktop]="isOverMobile"
                class="text__img d-none d-md-block d-lg-none"
              ></nscf-video-text>
              <nscf-rte [data]="element.text" [theme]="theme" class="text__content text__content--left"></nscf-rte>
              <div *ngIf="element.ctaLabel">
                <nscf-cta-button
                  [btnClass]="'cta-primary big-btn'"
                  [ctaUrl]="element.ctaUrl"
                  [text]="element.ctaLabel"
                  [theme]="theme"
                ></nscf-cta-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #withoutMediaDesktop>
    <div class="container">
      <div class="spacing-block-text">
        <div class="row">
          <div class="col-8 col-md-10 col-lg-8 mx-auto text text--article">
            <nscf-title
              *ngIf="element.title"
              [id]="'anchor_' + element.id"
              [selector]="element.isFirstTitle ? 'h1' : 'h2'"
              [title]="element.title"
              class="text-center"
              [shouldDisplaySeparator]="true"
            >
            </nscf-title>
            <nscf-rte [data]="element.text" [theme]="theme" class="text__content text__content--without-img"></nscf-rte>
            <nscf-widget-calculator
              [theme]="theme"
              *ngIf="element.widgetCalculator?.isActive"
              [data]="element.widgetCalculator"
              [isBrowser]="isBrowser"
            >
            </nscf-widget-calculator>
            <div *ngIf="element.ctaLabel">
              <nscf-cta-button
                [btnClass]="'cta-primary big-btn'"
                [ctaUrl]="element.ctaUrl"
                [text]="element.ctaLabel"
                [theme]="theme"
              ></nscf-cta-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<!-- MOBILE -->
<div *ngIf="!isOverMobile" class="text-mobile">
  <div *ngIf="element.video || element.image || element.map?.isActive; then mediaContent; else withoutMedia"></div>
  <ng-template #mediaContent>
    <div class="container">
      <div class="spacing-block-text">
        <div class="row">
          <div class="col-12 text">
            <nscf-title
              *ngIf="element.title"
              [id]="'anchor_' + element.id"
              [selector]="element.isFirstTitle ? 'h1' : 'h2'"
              [title]="element.title"
            >
            </nscf-title>
            <nscf-map *ngIf="element.map.isActive" [map]="element.map" [theme]="theme"></nscf-map>
            <img
              *ngIf="element.image?.url && !element.map?.isActive && !element.video?.video.length"
              [alt]="element.image?.alt"
              [src]="element.image?.url"
              loading="lazy"
              class="text__img"
            />
            <nscf-video-text *ngIf="element.video?.video.length" [data]="element.video" [isDesktop]="isOverMobile"></nscf-video-text>
            <nscf-rte [data]="element.text" [theme]="theme" class="text__content text__content--right"></nscf-rte>
            <nscf-widget-calculator
              *ngIf="element.widgetCalculator?.isActive"
              [theme]="theme"
              [data]="element.widgetCalculator"
              [isBrowser]="isBrowser"
            >
            </nscf-widget-calculator>
            <div *ngIf="element.ctaLabel">
              <nscf-cta-button
                [btnClass]="'cta-primary big-btn'"
                [ctaUrl]="element.ctaUrl"
                [text]="element.ctaLabel"
                [theme]="theme"
              ></nscf-cta-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #withoutMedia>
    <div class="container">
      <div class="spacing-block-text">
        <div class="row">
          <div class="col-12 mx-auto text">
            <nscf-title
              *ngIf="element.title"
              [id]="'anchor_' + element.id"
              [selector]="element.isFirstTitle ? 'h1' : 'h2'"
              [title]="element.title"
            >
            </nscf-title>
            <nscf-rte [data]="element.text" [theme]="theme" class="text__content text__content--without-img"></nscf-rte>
            <nscf-widget-calculator
              *ngIf="element.widgetCalculator?.isActive"
              [theme]="theme"
              [data]="element.widgetCalculator"
              [isBrowser]="isBrowser"
            >
            </nscf-widget-calculator>
            <div *ngIf="element.ctaLabel">
              <nscf-cta-button
                [btnClass]="'cta-primary big-btn'"
                [ctaUrl]="element.ctaUrl"
                [text]="element.ctaLabel"
                [theme]="theme"
              ></nscf-cta-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
