import { AsyncPipe, NgClass, NgIf, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Input, OnInit, PLATFORM_ID, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EffyButtonStyle, ThemeHelperService } from '@effy-tech/angular-common';
import { BasicThemedComponentDirective } from '@effy-tech/common';
import { Theme } from '@effy-tech/design-system';
import { map } from 'rxjs';
import { LoadImagesService } from '../../services/load-images.service.ts/load-images.service';
import { ConnectionComponent } from '../connection/connection.component';
import { CtaButtonComponent } from '../cta/cta-button/cta-button.component';
import { LogoComponent } from '../logo/logo.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { PhoneBlockComponent } from '../phone-block/phone-block.component';
import { HEADER_HEIGHT, IHeader, PhoneType, throttleScroll } from './header';

@Component({
  selector: 'nscf-new-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgIf, LogoComponent, PhoneBlockComponent, NavbarComponent, ConnectionComponent, CtaButtonComponent, AsyncPipe, NgClass],
  providers: [LoadImagesService],
  host: {
    '[class]': 'hostClasses()'
  }
})
export class HeaderComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly themeHelperService = inject(ThemeHelperService);
  @Input() data: IHeader;
  scrollOverHeightHeader: boolean;
  lastScrollPosition = 0;
  scrollBack = false;
  connected = signal(false);
  isRegulatory: boolean;
  isBrowser = isPlatformBrowser(this.platformId);
  readonly isNewArtisticDirection = toSignal(this.themeHelperService.isNewArtisticDirection$);
  readonly hostClasses = computed(() => this.isNewArtisticDirection() ? 'new-da' : 'old-da');
  readonly secondButtonStyle = toSignal(this.themeHelperService.theme$.pipe(
    map(theme => theme === Theme.EFFY ? EffyButtonStyle.PRIMARY : EffyButtonStyle.TERTIARY)
  ));

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.isRegulatory = this.data.phoneInsert?.phoneType === PhoneType.Reglementaire;
    if (this.data?.shouldDisplayPhoneInsert === false) {
      this.data.phoneInsert = null;
    }
  }

  @HostListener('window:scroll', [])
  @throttleScroll()
  onWindowScroll(): void {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.scrollOverHeightHeader = verticalOffset > HEADER_HEIGHT;
    this.scrollBack = verticalOffset < this.lastScrollPosition;
    this.lastScrollPosition = verticalOffset;
  }

  onConnected(connected: boolean) {
    this.connected.set(connected);
  }
}
